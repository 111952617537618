<template>
  <div style="height: 100%" class="_container">
    <v-card
      elevation="0"
      height="100%"
      class="d-flex flex-column"
      style="overflow: hidden"
    >
      <v-card-text style="height: 100%; overflow: hidden">
        <v-row style="max-height: 100%; overflow: scroll">
          <v-col xl="6" cols="12">
            <v-text-field
              label="Nome"
              outlined
              hide-details="auto"
              v-model="operatore.nome"
            />
          </v-col>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Cognome"
              outlined
              hide-details="auto"
              v-model="operatore.cognome"
            />
          </v-col>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Badge"
              outlined
              hide-details="auto"
              v-model="operatore.badge"
            />
          </v-col>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Data di nascita"
              type="date"
              outlined
              hide-details="auto"
              v-model="operatore.dataNascita"
            />
          </v-col>
          <v-col cols="12" xl="6">
            <v-select
              label="Sesso"
              type="date"
              :items="sessi"
              outlined
              hide-details="auto"
              v-model="operatore.sesso"
            />
          </v-col>
          <v-col cols="12" xl="6">
            <v-text-field
              label="Codice fiscale"
              outlined
              hide-details="auto"
              v-model="operatore.codiceFiscale"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              :color="operatore.admin ? 'primary' : null"
              width="100%"
              @click="operatore.admin = !operatore.admin"
            >
              <span>Admin</span>
              <v-icon style="position: absolute; left: 0">
                {{ operatore.admin ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4 justify-end">
        <v-btn
          width="100%"
          elevation="0"
          color="primary"
          @click="controllaOperatore"
          :loading="loading"
          :disabled="
            !operatore.nome ||
            !operatore.cognome ||
            !operatore.dataNascita ||
            !operatore.sesso
          "
        >
          Salva
        </v-btn>
      </v-card-actions>
    </v-card>
    <ControllaOperatoreDialog admins ref="controllaOperatoreDialog" />
    <v-snackbar v-model="pinNotvalidError" color="error" :timeout="3000">
      <h2 style="text-align: center">PIN errato</h2>
    </v-snackbar>
  </div>
</template>
<script>
import ControllaOperatoreDialog from "@/components/ControllaOperatoreDialog.vue";
export default {
  components: {
    ControllaOperatoreDialog
  },
  data() {
    return {
      id: null,
      loading: false,
      pinNotvalidError: false,
      operatore: {
        badge: "",
        nome: "", // required
        cognome: "", // required
        dataNascita: "", // required
        sesso: "", // required
        codiceFiscale: "",
        admin: false
      }
    };
  },
  methods: {
    controllaOperatore() {
      this.$refs.controllaOperatoreDialog.show(this.saveCallback);
    },
    async saveCallback(operatore, pin) {
      try {
        if (this.id) {
          await this.$store.dispatch("operatori/modificaOperatore", {
            id: this.id,
            payload: this.operatore,
            credentials: {
              operatore,
              pin
            }
          });
        } else {
          await this.$store.dispatch("operatori/aggiungiOperatore", {
            payload: this.operatore,
            credentials: {
              operatore,
              pin
            }
          });
        }
        this.$refs.controllaOperatoreDialog.close();
        this.$router.push("/operatori");
      } catch (error) {
        console.log(error.message);
        if (error.message === "NotAuthorizedError - Pin not valid") {
          this.pinNotvalidError = true;
        }
      }
    }
  },
  computed: {
    readonly() {
      return false;
    }
  },
  mounted() {
    const idParam = this.$route.params.id;
    if (idParam === "aggiungi") {
      this.operatore = {
        badge: "",
        nome: "", // required
        cognome: "", // required
        dataNascita: "", // required
        sesso: "", // required
        codiceFiscale: "",
        admin: false
      };
    } else {
      this.id = idParam;
      const operatore = this.$store.getters["operatori/operatori"].find(
        (operatore) => operatore.id === this.id
      );
      for (const key in this.operatore) {
        this.operatore[key] = operatore[key] || null;
      }
    }
  }
};
</script>
<style scoped></style>
